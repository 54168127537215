import type {
  HTTPMethod,
  PrepareRequestSettings,
} from 'nuxt-strict-fetch/types';

import type { paths } from '~/openapi/types';

type GetResponse<T> = T extends { responses: any }
  ? T['responses']['default']['content']['application/json']
  : null;

type GetBody<T> = T extends { requestBody: any }
  ? T['requestBody']['content']['application/json']
  : null;

type GetPath<T> = T extends { parameters: any }
  ? T['parameters']['path']
  : null;

type GetQuery<T> = T extends { parameters: any }
  ? T['parameters']['query']
  : null;

type Path<T = { [K in keyof paths]: K extends `/api/${infer I}` ? I : K }> =
  T[keyof T];

export const openapiStrictFetch = <
  U extends Path,
  M extends HTTPMethod = HTTPMethod.get,
>(
  options: Omit<
    PrepareRequestSettings<
      GetResponse<paths[`/api/${U}`][M]>,
      GetBody<paths[`/api/${U}`][M]>,
      GetPath<paths[`/api/${U}`][M]>,
      GetQuery<paths[`/api/${U}`][M]>
    >,
    'url' | 'method' | 'schemas'
  > & { url: U; method?: M },
) =>
  StrictFetch.prepare<
    GetResponse<paths[`/api/${U}`][M]>,
    GetBody<paths[`/api/${U}`][M]>,
    GetPath<paths[`/api/${U}`][M]>,
    GetQuery<paths[`/api/${U}`][M]>
  >({
    ...options,
    url: (params) => {
      if (!params) return options.url;

      return Object.entries(params).reduce<string>(
        (acc, [key, value]) => acc.replaceAll(`{${key}}`, value.toString()),
        options.url,
      );
    },
  });
